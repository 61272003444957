import { type ReactNode } from 'react';
import cx from 'clsx';
import { SkeletonLoader } from '@repo/design-system-kit';
import styles from './placeholder.strict-module.css';

export function Placeholder(): ReactNode {
  return (
    <>
      <td className={cx(styles.empty, styles.cell)} />
      <td className={styles.cell}>
        <div className={styles.connection}>
          <SkeletonLoader.Avatar data-test-placeholder-avatar />
          <SkeletonLoader.Line data-test-placeholder-line width="91px" />
        </div>
      </td>
      <td className={styles.cell}>
        <SkeletonLoader.Header
          data-test-placeholder-header
          lines={1}
          smallLinesWidth="84px"
          width="133px"
        />
      </td>
      <td className={styles.cell}>
        <SkeletonLoader.Line data-test-placeholder-line width="90px" />
      </td>
      <td className={styles.cell}>
        <SkeletonLoader.Line data-test-placeholder-line width="90px" />
      </td>
      <td className={cx(styles.empty, styles.cell)} />
    </>
  );
}

import { ExternalLink } from '@repo/design-system-kit';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import styles from './styles.strict-module.css';

export function MobileAppRedirect(): ReactNode {
  const { formatMessage } = useIntl();
  // TODO: add the correct url for the app
  const url = '';
  return (
    <div className={styles.centered}>
      <img alt="" className={styles['qonto-logo']} src="illustrations/app/qonto-logo.svg" />
      <p className="title-2" data-test-title="">
        {formatMessage({ id: 'mobile-app-redirect.title' })}
      </p>
      <p className={cx(styles.subtitle, 'body-1')} data-test-subtitle="">
        {formatMessage({ id: 'mobile-app-redirect.subtitle' })}
      </p>

      <ExternalLink
        className={cx('btn--primary btn-strech', styles['cta-button'])}
        display="button"
        href={url}
      >
        <span className={styles.cta} data-test-cta="">
          {formatMessage({ id: 'mobile-app-redirect.cta' })}
          <img alt="" className={styles.icon} src="icon/external-link-alt.svg" />
        </span>
      </ExternalLink>
    </div>
  );
}

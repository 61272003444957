import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import dayjs from 'dayjs';
import { BadgeStatus, type StatusProps } from '@repo/design-system-kit';
import {
  IconErrorFilled,
  IconWarningSignFilled,
  IconCheckmarkRoundedFilled,
} from '@repo/monochrome-icons';
import { formatDate } from '@repo/design-system-kit';
import { Avatar } from 'qonto/react/components/connections/avatar';
import {
  BANK_CONNECTION_FAILURE_STATUSES,
  BANK_CONNECTION_STATUS,
  CONNECTION_EXPIRATION_STATUS,
  SOLUTION_INSTANCE_STATUSES,
} from 'qonto/constants/connect';
import {
  GMI_STATUSES_ACTION_REQUIRED,
  GMI_STATUSES_CONNECTING_STATUSES,
  GMI_STATUSES_FAILED_STATUSES,
  GMI_STATUSES_SUCCESS_EVENTS,
} from 'qonto/constants/gmi-solution-instance';
import styles from './row.strict-module.css';

interface ConnectionsTableRowProps {
  connectionLogo: string;
  connectionName: string;
  connectionStatus: string;
  displayStatusBadge?: boolean;
  expiresAt: string;
  grantedAt: string;
  isSidebarOpen?: boolean;
  lastSuccessfulSyncAt?: string;
  memberName: string;
  memberTeam: string;
}

export function Row({
  connectionLogo,
  connectionName,
  connectionStatus,
  displayStatusBadge,
  expiresAt,
  grantedAt,
  isSidebarOpen,
  lastSuccessfulSyncAt,
  memberName,
  memberTeam,
}: ConnectionsTableRowProps): ReactNode {
  const intl = useIntl();

  const getStatusLevel = (): string | undefined => {
    switch (true) {
      case BANK_CONNECTION_FAILURE_STATUSES.includes(connectionStatus):
        return 'failure';

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.EXPIRING:
        return 'warning';

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.EXPIRED:
        return 'error';

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.ACTIVE:
        return 'info';

      default:
        return undefined;
    }
  };

  const getIconComponent = (): ReactNode => {
    switch (true) {
      case BANK_CONNECTION_FAILURE_STATUSES.includes(connectionStatus):
        return <IconErrorFilled data-test-icon="icon_error_filled" />;

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.EXPIRING:
      case connectionStatus === CONNECTION_EXPIRATION_STATUS.EXPIRED:
        return <IconWarningSignFilled data-test-icon="icon_warning_sign_filled" />;

      case connectionStatus === BANK_CONNECTION_STATUS.SYNCHRONIZED:
      case connectionStatus === BANK_CONNECTION_STATUS.SYNC_IN_PROGRESS:
      case connectionStatus === CONNECTION_EXPIRATION_STATUS.ACTIVE:
        return <IconCheckmarkRoundedFilled data-test-icon="icon_checkmark_rounded_filled" />;

      default:
        return null;
    }
  };

  const getStatusMessage = (): string => {
    const expirationDate = formatDate({
      date: expiresAt,
      locale: intl.locale,
      token: 'date-year-s',
    });

    switch (true) {
      case BANK_CONNECTION_FAILURE_STATUSES.includes(connectionStatus): {
        const relativeLastSyncTime = dayjs(lastSuccessfulSyncAt).fromNow();

        return intl.formatMessage(
          { id: 'settings.connections.status.failed' },
          { relativeTime: relativeLastSyncTime }
        );
      }

      case connectionStatus === BANK_CONNECTION_STATUS.SYNCHRONIZED:
      case connectionStatus === BANK_CONNECTION_STATUS.SYNC_IN_PROGRESS:
      case connectionStatus === CONNECTION_EXPIRATION_STATUS.ACTIVE:
        return intl.formatMessage(
          { id: 'settings.connections.status.connected' },
          { expirationDate }
        );

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.EXPIRING:
        return intl.formatMessage(
          { id: 'settings.connections.status.expires-soon' },
          { expirationDate }
        );

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.EXPIRED:
        return intl.formatMessage(
          { id: 'settings.connections.status.expired' },
          { expirationDate }
        );

      case connectionStatus === CONNECTION_EXPIRATION_STATUS.UNKNOWN:
        return '-';

      default:
        return '';
    }
  };

  const getBadgeStatusProps = (): StatusProps => {
    switch (true) {
      case GMI_STATUSES_SUCCESS_EVENTS.includes(connectionStatus):
      case connectionStatus === SOLUTION_INSTANCE_STATUSES.ENABLED:
        return {
          level: 'validated',
          text: intl.formatMessage({ id: 'settings.connections.status.active' }),
        };
      case GMI_STATUSES_ACTION_REQUIRED.includes(connectionStatus):
        return {
          level: 'warning',
          text: intl.formatMessage({ id: 'settings.connections.list.status-action-needed' }),
        };
      case GMI_STATUSES_FAILED_STATUSES.includes(connectionStatus):
        return {
          level: 'error',
          text: intl.formatMessage({ id: 'settings.connections.list.status-failed' }),
        };
      case GMI_STATUSES_CONNECTING_STATUSES.includes(connectionStatus):
        return {
          level: 'waiting',
          text: intl.formatMessage({ id: 'settings.connections.list.status-connecting' }),
        };
      default:
        return {
          text: '',
        };
    }
  };

  const statusLevel = getStatusLevel();

  return (
    <>
      <td aria-hidden="true" className={cx(styles.empty, styles.cell)} />

      <td className={styles.cell} data-test-connection>
        <div className={styles.connection}>
          <div className={styles['connection-avatar']}>
            <Avatar
              avatar={connectionLogo}
              className="caption"
              data-test-avatar=""
              name={connectionName}
            />
          </div>
          <p className={styles['connection-name']} data-test-connection-name>
            {connectionName}
          </p>
        </div>
      </td>

      <td className={styles.cell} data-test-member>
        <p className={styles['member-name']}>{memberName}</p>
        <p className={styles['member-team']}>{memberTeam}</p>
      </td>

      <td className={cx(styles.cell, isSidebarOpen && styles.hidden)} data-test-granted-at>
        {formatDate({
          date: grantedAt,
          locale: intl.locale,
          token: 'date-year-s',
        })}
      </td>

      <td className={styles.cell} data-test-status>
        {displayStatusBadge ? (
          <BadgeStatus {...getBadgeStatusProps()} data-test-status-badge />
        ) : (
          <p className={cx(styles.status, statusLevel && styles[statusLevel])}>
            {getIconComponent()}
            <span>{getStatusMessage()}</span>
          </p>
        )}
      </td>

      <td aria-hidden="true" className={cx(styles.empty, styles.cell)} />
    </>
  );
}

import { type ReactNode } from 'react';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { Button, Disclaimer } from '@repo/design-system-kit';
import { IconCrossOutlined, IconExternalOutlined } from '@repo/monochrome-icons';
import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { Avatar } from 'qonto/react/components/connections/avatar';
import {
  BANK_CONNECTION_FAILURE_STATUSES,
  BANK_CONNECTION_STATUS,
  CONNECTION_EXPIRATION_STATUS,
} from 'qonto/constants/connect';
import styles from './header.strict-module.css';

type DisclaimerLevel = 'info' | 'warning' | 'error';

interface DisclaimerConfig {
  level: DisclaimerLevel;
  translationKey: string;
  translationParam?: 'expirationDate' | 'bankName';
}

const DISCLAIMERS = new Map<string, DisclaimerConfig>([
  [
    CONNECTION_EXPIRATION_STATUS.ACTIVE,
    {
      level: 'info',
      translationKey: 'settings.connections.sidebar.disclaimer.bank-expiry-date',
      translationParam: 'expirationDate',
    },
  ],
  [
    BANK_CONNECTION_STATUS.SYNCHRONIZED,
    {
      level: 'info',
      translationKey: 'settings.connections.sidebar.disclaimer.bank-expiry-date',
      translationParam: 'expirationDate',
    },
  ],
  [
    CONNECTION_EXPIRATION_STATUS.EXPIRING,
    {
      level: 'warning',
      translationKey: 'settings.connections.sidebar.alert',
      translationParam: 'expirationDate',
    },
  ],
  [
    CONNECTION_EXPIRATION_STATUS.EXPIRED,
    {
      level: 'warning',
      translationKey: 'settings.connections.sidebar.expired',
      translationParam: 'expirationDate',
    },
  ],
  [
    BANK_CONNECTION_STATUS.PROVIDER_ISSUE,
    {
      level: 'error',
      translationKey: 'settings.connections.sidebar.error.technical-issue.disclaimer',
    },
  ],
  [
    BANK_CONNECTION_STATUS.SUSPENDED,
    {
      level: 'error',
      translationKey: 'settings.connections.sidebar.error.fin-api-fixable.disclaimer',
    },
  ],
  [
    BANK_CONNECTION_STATUS.PASSWORD_EXPIRED,
    {
      level: 'error',
      translationKey: 'settings.connections.sidebar.error.password-expired.disclaimer',
      translationParam: 'bankName',
    },
  ],
  [
    BANK_CONNECTION_STATUS.PASSWORD_UPDATED,
    {
      level: 'error',
      translationKey: 'settings.connections.sidebar.error.wrong-pass.disclaimer',
      translationParam: 'bankName',
    },
  ],
  [
    BANK_CONNECTION_STATUS.ACTION_REQUIRED_ON_BANK,
    {
      level: 'error',
      translationKey: 'settings.connections.sidebar.error.action-needed.disclaimer',
      translationParam: 'bankName',
    },
  ],
]);

interface HeaderProps {
  connectionId?: string;
  connectionStatus?: string;
  connectionExpiresAt?: string;
  connectionName?: string;
  connectionLogo?: string;
  closeSidebar?: () => void;
  reconnect?: () => void;
  sidebarCloseId?: string;
  title?: string;
  disclaimer?: {
    level?: DisclaimerLevel;
    message?: string;
    buttonText?: string;
    troubleshootingUrl?: string;
  };
}

export function Header({
  connectionId,
  connectionStatus,
  connectionExpiresAt,
  connectionName,
  connectionLogo,
  closeSidebar,
  reconnect,
  sidebarCloseId,
  title,
  disclaimer,
  ...props
}: HeaderProps): ReactNode {
  const { formatMessage, locale } = useIntl();

  const showDisclaimer =
    Boolean(disclaimer) ||
    (connectionStatus &&
      [
        BANK_CONNECTION_STATUS.SYNCHRONIZED,
        CONNECTION_EXPIRATION_STATUS.ACTIVE,
        CONNECTION_EXPIRATION_STATUS.EXPIRING,
        CONNECTION_EXPIRATION_STATUS.EXPIRED,
        ...BANK_CONNECTION_FAILURE_STATUSES,
      ].includes(connectionStatus));

  const showReconnectButton = Boolean(disclaimer?.troubleshootingUrl);

  const disclaimerLevel =
    disclaimer?.level ?? (connectionStatus ? DISCLAIMERS.get(connectionStatus)?.level : undefined);

  const getDisclaimerMessage = (): string | undefined => {
    if (disclaimer) {
      return disclaimer.message;
    }

    const disclaimerConfig = connectionStatus ? DISCLAIMERS.get(connectionStatus) : undefined;
    const translationKey = disclaimerConfig?.translationKey;
    const translationParam = disclaimerConfig?.translationParam;

    if (!translationKey) return;

    let translationParams;

    switch (translationParam) {
      case 'expirationDate':
        translationParams = connectionExpiresAt
          ? {
              expirationDate: dateToken({
                date: connectionExpiresAt,
                locale,
                token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
              }),
            }
          : undefined;
        break;

      case 'bankName':
        translationParams = { bankName: connectionName };
        break;

      default:
        translationParams = undefined;
    }

    return formatMessage({ id: translationKey }, translationParams);
  };

  const disclaimerMessage = getDisclaimerMessage();
  const disclaimerButtonText =
    disclaimer?.buttonText || formatMessage({ id: 'settings.connections.sidebar.renew-cta' });

  return (
    <div
      className={styles['connection-sidebar-header']}
      data-test-connection-sidebar={connectionId}
      {...props}
    >
      <div className={styles.title}>
        <h2 className="body-1">{title}</h2>
        <Button
          aria-label={formatMessage({ id: 'btn.close' })}
          className={styles.close}
          data-test-close-button
          iconOnly
          id={sidebarCloseId}
          onPress={closeSidebar}
          type="button"
          variant="tertiary"
        >
          <IconCrossOutlined aria-hidden="true" />
        </Button>
      </div>
      <div className={styles['api-client']}>
        <div className={styles['api-client-avatar']}>
          <Avatar
            avatar={connectionLogo}
            className="title-4"
            data-test-connections-sidebar-client-avatar
            name={connectionName}
          />
        </div>
        <div className={styles['api-client-name']}>
          <p className="title-3" data-test-connections-sidebar-client-name>
            {connectionName}
          </p>
        </div>
        {showDisclaimer ? (
          <>
            <Disclaimer.Inline
              className={cx(
                styles.disclaimer,
                connectionStatus ? styles[connectionStatus] : undefined
              )}
              data-test-connections-sidebar-disclaimer
              level={disclaimerLevel}
            >
              <span>{disclaimerMessage}</span>
            </Disclaimer.Inline>

            {showReconnectButton ? (
              <Button
                aria-label={disclaimerButtonText}
                className={styles['connect-button']}
                data-test-connections-sidebar-reconnect
                onPress={reconnect}
                size="large"
                stretch
                variant="primary"
              >
                {disclaimerButtonText}
                <IconExternalOutlined aria-hidden="true" className="ml-8" />
              </Button>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
}

import { type ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Popup, Button } from '@repo/design-system-kit';
import kybPendingModalIllustration from '../../../static/illustrations/pagopa/pagopa-kyb-pending-modal.svg';
import styles from './styles.strict-module.css';

interface KybPendingModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function KybPendingModal({ isOpen, onClose }: KybPendingModalProps): ReactElement | null {
  const { formatMessage } = useIntl();
  const TEST_PORTAL_CONTAINER = document.getElementById('ember-testing') ?? undefined;

  return (
    <Popup.Root
      isDismissable
      isOpen={isOpen}
      onOpenChange={onClose}
      portalContainer={TEST_PORTAL_CONTAINER}
    >
      <Popup.CloseButton onPress={onClose} />
      <Popup.Illustration className={styles['illustration-container']}>
        <img
          alt=""
          className={styles.illustration}
          data-test-id="pagopa-kyb-pending-modal-illustration"
          src={kybPendingModalIllustration}
        />
      </Popup.Illustration>
      <Popup.Header>
        <Popup.Title>
          <FormattedMessage id="pagopa.pending-validation-modal.title" />
        </Popup.Title>
        <Popup.Subtitle>
          <FormattedMessage id="pagopa.pending-validation-modal.subtitle" />
        </Popup.Subtitle>
      </Popup.Header>
      <Popup.Footer>
        <Button
          aria-label={formatMessage({ id: 'btn.close' })}
          data-test-id="pagopa-kyb-pending-modal-cta"
          onPress={onClose}
          variant="primary"
        >
          <FormattedMessage id="btn.close" />
        </Button>
      </Popup.Footer>
    </Popup.Root>
  );
}

import type { ReactNode } from 'react';
import dayjs from 'dayjs';
import type { UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';
import { useUpcomingTransactionsContext } from '../upcoming-transactions-context';
import { useMenuConfig } from './hooks/use-menu-config';
import { ActionsMenu } from './actions-menu';
import { Scheduler } from './scheduler';

interface ActionsComponentProps {
  upcomingTransaction: UpcomingTransaction | null;
  handleEditUpcomingTransaction: (upcomingTransaction: UpcomingTransaction) => void;
  updatedDateForPreviewLine: (
    date: string | null,
    amount: string | null,
    originalDate: string | null,
    side: string | null
  ) => void;
}

export function ActionsComponent({
  upcomingTransaction,
  handleEditUpcomingTransaction,
  updatedDateForPreviewLine,
}: ActionsComponentProps): ReactNode {
  const { state } = useUpcomingTransactionsContext();
  const { activeScheduledTransactionId } = state;
  const { mainAction, sections, useActionsMenu } = useMenuConfig(
    upcomingTransaction,
    handleEditUpcomingTransaction
  );

  const handleupdatedDateForPreviewLine = (date: string | null): void => {
    const amount = upcomingTransaction?.amount.value ?? null;
    const side = upcomingTransaction?.side ?? null;
    const originalDate =
      upcomingTransaction?.expected_date ?? upcomingTransaction?.due_date ?? null;
    updatedDateForPreviewLine(date, amount, originalDate, side);
  };

  const handlePayNowHover = (amount: string | null): void => {
    const side = 'debit';
    const originalDate =
      upcomingTransaction?.expected_date ?? upcomingTransaction?.due_date ?? null;
    updatedDateForPreviewLine(dayjs().format('YYYY-MM-DD'), amount, originalDate, side);
  };

  if (activeScheduledTransactionId === upcomingTransaction?.id) {
    return (
      <Scheduler
        upcomingTransaction={upcomingTransaction}
        updatedDateForPreviewLine={handleupdatedDateForPreviewLine}
      />
    );
  }

  if (!activeScheduledTransactionId) {
    return (
      <ActionsMenu
        handleEditUpcomingTransaction={handleEditUpcomingTransaction}
        handlePayNowHover={handlePayNowHover}
        mainAction={mainAction}
        sections={sections}
        upcomingTransaction={upcomingTransaction}
        useActionsMenu={useActionsMenu}
      />
    );
  }

  return null;
}

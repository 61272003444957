import { Button, LottiePlayer } from '@repo/design-system-kit';
import styles from './feedback.strict-module.css';

// NOTE: Please feel free to map here and in the feedbackLotties object any other feedback status you might need.
export enum FlowFeedbackStatus {
  Success = 'success',
  Pending = 'pending',
}

const feedbackLotties: Record<FlowFeedbackStatus, string> = {
  [FlowFeedbackStatus.Success]: '/lotties/general_success.json',
  [FlowFeedbackStatus.Pending]: '/lotties/check-info.json',
};

interface FlowFeedbackProps {
  feedback: FlowFeedbackStatus;
  title: string;
  subtitle: string;
  primaryAction?: { label: string; onClick: () => void };
  secondaryAction?: { label: string; onClick: () => void };
}

export function FlowFeedback({
  title,
  subtitle,
  primaryAction,
  secondaryAction,
  feedback,
}: FlowFeedbackProps): React.ReactNode {
  const lottie = feedbackLotties[feedback];
  return (
    <main className={styles.wrapper}>
      <section className={styles.content}>
        <figure className={styles.illustration}>
          <LottiePlayer data-test-lottie={lottie} loop={false} path={lottie} />
        </figure>
        <article className={styles.feedback}>
          <h1 className="title-1">{title}</h1>
          <p className="body-1">{subtitle}</p>
        </article>
        <div className={styles.actions}>
          {primaryAction ? (
            <Button
              aria-label={primaryAction.label}
              data-feedback-action="primary"
              onPress={primaryAction.onClick}
              size="medium"
              variant="primary"
            >
              {primaryAction.label}
            </Button>
          ) : null}
          {secondaryAction ? (
            <Button
              aria-label={secondaryAction.label}
              data-feedback-action="secondary"
              onPress={secondaryAction.onClick}
              size="medium"
              variant="tertiary"
            >
              {secondaryAction.label}
            </Button>
          ) : null}
        </div>
      </section>
    </main>
  );
}

import type { ReactElement } from 'react';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { SuggestedProducts } from '@repo/domain-kit/pricing';
import { ConfirmLayout } from 'qonto/react/pricing/components/confirm-layout';
import { ConfirmHeader } from 'qonto/react/pricing/components/confirm-header';
import { ConfirmBillingSummary } from 'qonto/react/pricing/components/confirm-billing-summary';
import type {
  SubscriptionChangeDataContext,
  TargetSubscription,
  PriceBreakdown,
} from 'qonto/react/components/flows/subscription-change/data-context.type';
import { ConfirmDisclaimers } from 'qonto/react/components/flows/subscription-change/confirm-trial/confirm-disclaimers';
import styles from './styles.strict-module.css';

interface ConfirmUpgradeProps {
  context: SubscriptionChangeDataContext;
  hasProductChangeNotAvailableError: boolean;
  header: ReactElement;
  isDisabled: boolean;
  isLoading: boolean;
  onConfirm: () => void;
  subtitle: string;
  targetSubscription?: TargetSubscription;
  title: string;
  onSuggestedProductToggle: (isSelected: boolean, product: TargetSubscription) => void;
}

export function ConfirmUpgrade({
  context,
  hasProductChangeNotAvailableError,
  header,
  isLoading,
  isDisabled,
  onConfirm,
  subtitle,
  targetSubscription,
  title,
  onSuggestedProductToggle,
}: ConfirmUpgradeProps): ReactElement {
  const { formatNumber, formatMessage, formatDate } = useIntl();
  const { estimatedPrice, suggestedProducts } = context;

  const [products, setProducts] = useState<PriceBreakdown[]>([]);
  const price = formatNumber(Number(targetSubscription?.prorated_amount.value), {
    style: 'currency',
    currency: targetSubscription?.prorated_amount.currency,
  });

  const nextBillingDate = formatDate(estimatedPrice.next_billing_date, { format: 'long' });

  useEffect(() => {
    const productsArr: PriceBreakdown[] = [];

    if (title && price) {
      productsArr.push({
        title,
        price,
        testId: 'data-test-price-plan-breakdown',
      });
    }

    setProducts(productsArr);
  }, [price, title]);

  return (
    <ConfirmLayout
      header={header}
      subtitle={formatMessage(
        {
          id: 'subscription.change.confirmation.free-trial.subtitle',
        },
        {
          date: nextBillingDate,
        }
      )}
      testId="data-test-free-confirm-trial-revamp"
    >
      <>
        <div className={styles.container}>
          <ConfirmHeader pricePlan={{ price, subtitle, title }} />
          {suggestedProducts?.length ? (
            <SuggestedProducts
              onToggle={(isSelected, { id }) => {
                onSuggestedProductToggle(
                  isSelected,
                  suggestedProducts.find(
                    ({ product_id: productId }) => productId === id
                  ) as unknown as TargetSubscription
                );
              }}
              products={suggestedProducts.map(product => ({
                code: product.product_code,
                id: product.product_id,
                // TODO add title
                title: 'title',
                // TODO add description
                description: 'description',
                price: product.prorated_amount,
                // TODO add priceNote
                priceNote: 'priceNote',
                // TODO add isSelected
                isSelected: false,
                // TODO add isFree
                isFree: false,
              }))}
              title={formatMessage({
                id: 'subscription.change.confirmation.addon-upsell.plan.title',
              })}
            />
          ) : null}
        </div>
        <ConfirmBillingSummary
          billing={formatMessage(
            {
              id: 'subscription.change.confirmation.summary.upsizing.subtitle',
            },
            {
              date: nextBillingDate,
            }
          )}
          confirmButtonTestId="data-test-free-trial-cta-revamp"
          confirmText={formatMessage({ id: 'subscription.change.addons.cta.free-trial' })}
          isCtaDisabled={isDisabled}
          isLoading={isLoading}
          onConfirm={onConfirm}
          price={formatNumber(Number(estimatedPrice.vat_included_amount.value), {
            style: 'currency',
            currency: estimatedPrice.vat_included_amount.currency,
          })}
          products={products}
          testId="data-test-confirm-trial-total"
          total={formatMessage({ id: 'subscription.change.confirmation.charge' })}
          totalEstimate={estimatedPrice}
        >
          <ConfirmDisclaimers
            context={context}
            hasProductChangeNotAvailableError={hasProductChangeNotAvailableError}
          />
        </ConfirmBillingSummary>
      </>
    </ConfirmLayout>
  );
}

import { type ReactNode, useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSegment } from '@repo/poly-hooks/segment';
import { useEmberService, useFlags } from '@qonto/react-migration-toolkit/react/hooks';
import { SubscriptionSuccess } from '@repo/domain-kit/pricing';
import styles from './success.strict-module.css';

interface SubscriptionChangeSuccessProps {
  context: {
    refererPage?: string;
    newPlan: string;
    previousPlan: string;
    recurrence: string;
    currentPlan: string;
    changeType: string;
    currentPricePlanCode: string;
    subscription?: { product: { code: 'string' } };
  };
  transitionToNext: () => void;
}

export function Success({ context, transitionToNext }: SubscriptionChangeSuccessProps): ReactNode {
  const [textAreaValue, setTextAreaValue] = useState('');
  const hasTrackedRef = useRef(false);

  const intl = useIntl();
  const segment = useSegment();
  const organizationManager = useEmberService('organizationManager');
  const toastFlashMessages = useEmberService('toastFlashMessages');
  const { featureBooleanImprovedFeedbackOnSuccess } = useFlags();

  useEffect(() => {
    // Skip if we've already tracked this event to prevent duplicates
    if (hasTrackedRef.current) {
      return;
    }

    const { changeType, currentPlan, recurrence, currentPricePlanCode, subscription } = context;

    // If the user comes directly to the success page after the context has been cleared (aka started a new flow)
    // subscription is undefined from the context
    if (!subscription) {
      return;
    }

    if (changeType === 'change-recurrence') {
      segment.track('account-closing_change-recurrence_completed', {
        current_plan: currentPlan,
      });
    } else if (changeType === 'switch-plan') {
      segment.track('account-closing_switch-plan_completed', {
        current_plan: currentPlan,
      });
    } else if (changeType === 'annual-switch-plan') {
      segment.track('account-closing_annual-switch-plan_completed', {
        current_plan: currentPlan,
      });
    }

    segment.track('checkout_displayed_success', {
      target_plan_recurrence: recurrence,
      target_plan: subscription.product.code,
      current_plan: currentPricePlanCode,
    });

    // Mark that we've sent the tracking events
    hasTrackedRef.current = true;
  }, [context, segment]);

  const hasNewFeedbackTextArea = featureBooleanImprovedFeedbackOnSuccess;

  const isConnectUpsell = context.refererPage?.includes('connect');

  let CTAText = intl.formatMessage({ id: 'btn.close' });
  if (hasNewFeedbackTextArea) {
    CTAText = intl.formatMessage({ id: 'subscription.change.success.feedback-cta' });
  } else if (isConnectUpsell) {
    CTAText = intl.formatMessage({ id: 'subscription.change.success.connect-cta' });
  }

  const subtitle = isConnectUpsell
    ? intl.formatMessage({ id: 'subscription.change.success.connect-subtitle' })
    : intl.formatMessage({ id: 'subscription.change.success.subtitle' });

  const typeformLink = (): string => {
    const membershipId = organizationManager.organization.id;
    const currentPlan = context.newPlan;
    const previousPlan = context.previousPlan;
    const recurrence = context.recurrence;
    return `${intl.formatMessage({ id: 'subscription.change.success.feedback-linkUrl' })}#membership_id=${membershipId}&current_plan=${currentPlan}&previous_plan=${previousPlan}&recurrence=${recurrence}`;
  };

  const handleClick = (): void => {
    if (hasNewFeedbackTextArea) {
      const feedback = textAreaValue.trim();
      if (feedback) {
        const { recurrence, newPlan, previousPlan } = context;
        segment.track('plans_change_confirmation_success_feedback_clicked', {
          membership_id: organizationManager.membership.id,
          input_content: feedback,
          target_plan: newPlan,
          current_plan: previousPlan,
          recurrence,
        });
        toastFlashMessages.toastInfo(
          intl.formatMessage({ id: 'subscription.change.success.feedback-submitted' })
        );
      }
    } else {
      segment.track('plans_change_confirmation_success_closed_clicked');
    }
    transitionToNext();
  };

  const onUpdateTextArea = (value: string): void => {
    setTextAreaValue(value);
  };

  const handleClickShareFeedback = (): void => {
    segment.track('plans_change_confirmation_success_feedback_clicked', {
      current_plan: context.newPlan,
      previous_plan: context.previousPlan,
    });
  };

  return (
    <div className={styles.success} data-test-step-id="success">
      <SubscriptionSuccess
        ctaText={CTAText}
        hasNewFeedbackStyle={Boolean(hasNewFeedbackTextArea)}
        onClick={handleClick}
        onClickShareFeedback={handleClickShareFeedback}
        onUpdateTextArea={onUpdateTextArea}
        shareFeedbackLink={typeformLink()}
        shareFeedbackSubtitle={intl.formatMessage({
          id: 'subscription.change.success.subtitle-feedback',
        })}
        shareFeedbackText={intl.formatMessage({ id: 'subscription.change.success.feedback-cta' })}
        subtitle={subtitle}
        textAreaLabel={intl.formatMessage({ id: 'subscription.change.success.subtitle-feedback' })}
        textAreaPlaceholder={intl.formatMessage({
          id: 'subscription.change.addons.success.input.feedback',
        })}
        textAreaValue={textAreaValue}
        title={intl.formatMessage({ id: 'subscription.change.success.title' })}
      />
    </div>
  );
}

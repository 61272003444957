import { FormattedMessage } from 'react-intl';
import { type ReactNode } from 'react';
import type { ColumnDef, CellContext } from '@tanstack/react-table';
import { BalanceCell, ExpectedDate } from '@repo/domain-kit/shared';
import { type UpcomingTransaction } from 'qonto/react/hooks/use-upcoming-transactions';
import { amountWithSideSorting } from '../../../utils/sort-amount-with-side';
import { TransactionCounterpartyCell } from './cells/transaction-counterparty-cell';
import { HideButtonCell } from './cells/hide-button-cell';
import { ActionsCell } from './cells/actions-cell';

export const columns: ColumnDef<UpcomingTransaction>[] = [
  {
    accessorKey: 'counterparty_name',
    header: () => (
      <FormattedMessage id="cash-flow.upcoming-transactions.table.column.counterparty" />
    ),
    enableSorting: true,
    cell: info => <TransactionCounterpartyCell info={info} />,
  },
  {
    accessorKey: 'amount.value',
    header: () => <FormattedMessage id="cash-flow.upcoming-transactions.table.column.amount" />,
    enableSorting: true,
    sortingFn: amountWithSideSorting,
    cell: info => (
      <BalanceCell
        amount={{
          value: parseFloat(info.row.original.amount.value),
          currency: info.row.original.amount.currency,
        }}
        displayCurrencySymbol
        side={info.row.original.side}
      />
    ),
  },
  {
    accessorKey: 'due_date',
    header: () => (
      <FormattedMessage id="cash-flow.upcoming-transactions.table.column.expected-date" />
    ),
    enableSorting: true,
    cell: info => <ExpectedDate date={info.row.original.due_date} />,
  },
  {
    accessorKey: 'action',
    header: () => <FormattedMessage id="cash-flow.upcoming-transactions.table.column.actions" />,
    cell: (cellContext: CellContext<UpcomingTransaction, unknown>): ReactNode => {
      const displayActionCell = ACTIONABLE_TRANSACTION_TYPES.includes(
        cellContext.row.original.provider_object_type
      );

      const { handleEditUpcomingTransaction } = cellContext.table.options.meta as {
        handleEditUpcomingTransaction: (upcomingTransaction: UpcomingTransaction) => void;
      };

      const { handleUpdatedDateForPreviewLine } = cellContext.table.options.meta as {
        handleUpdatedDateForPreviewLine: (
          date: string | null,
          amount: string | null,
          originalDate: string | null,
          side: string | null
        ) => void;
      };

      return displayActionCell
        ? (ActionsCell(
            cellContext,
            handleEditUpcomingTransaction,
            handleUpdatedDateForPreviewLine
          ) as ReactNode)
        : HideButtonCell(cellContext);
    },
  },
];

const ACTIONABLE_TRANSACTION_TYPES = [
  'manual_entry',
  'supplier_invoice',
  'receivable_invoice',
  'transfer',
];

import cx from 'clsx';
import { Disclaimer, SelectorToggle, Tooltip } from '@repo/design-system-kit';
import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TrackRender } from 'qonto/react/components/track-render';
import { StaticThemedAsset } from 'qonto/react/components/static-themed-asset';
import styles from './toggle-basic.strict-module.css';

interface ToggleBasicProps {
  isChecked?: boolean;
  isToggleDisabled?: boolean;
  onClick?: () => void;
  origin: string;
}

export function ToggleBasic({
  isChecked,
  isToggleDisabled,
  onClick,
  origin,
}: ToggleBasicProps): ReactNode {
  const { formatMessage } = useIntl();

  return (
    <>
      <TrackRender eventName="pay-later_toggle_displayed" params={{ origin }} />
      <Tooltip
        ariaLabel={
          isToggleDisabled
            ? formatMessage({ id: 'transfers.warnings.pay-later.tooltip.not-available' })
            : undefined
        }
        buttonProps={{ className: styles.tooltipTrigger }}
        closeDelay={0}
        data-test-pay-later-tooltip
        data-test-tooltip-enabled={isToggleDisabled}
        delay={100}
        isDisabled={!isToggleDisabled}
        label={
          isToggleDisabled
            ? formatMessage({ id: 'transfers.warnings.pay-later.tooltip.not-available' })
            : null
        }
        needsButton
        placement="top right"
      >
        <SelectorToggle
          checked={isChecked}
          className={cx(!isToggleDisabled && styles.tooltipDisabled, styles.selectorToggle)}
          content={
            <div className={styles.displayBlock}>
              <Disclaimer.Inline level="info">
                <FormattedMessage id="transfers.sepa.new.summary.pay-later.toggle.disclaimer" />
              </Disclaimer.Inline>
            </div>
          }
          data-test-pay-later-toggle-basic
          icon={
            <StaticThemedAsset
              alt=""
              aria-hidden="true"
              assetPath="/illustrations/ill-sspot-pay-later-pay-later.svg"
            />
          }
          isDisabled={isToggleDisabled}
          onChange={onClick}
          subtitle={formatMessage({ id: 'transfers.sepa.new.summary.pay-later.toggle.subtitle' })}
          title={formatMessage({ id: 'transfers.sepa.new.summary.pay-later.title' })}
        />
      </Tooltip>
    </>
  );
}
